import React from 'react';
import styled from 'styled-components';
import type { ProxyTypeEnum } from 'venn-api';
import { DropMenu, TooltipPosition } from 'venn-ui-kit';
import { getFormattedProxyType, getProxyTypeDescription } from 'venn-utils';
import type { FundToProxy } from '../types';
import type { ReturnsRangeInfo } from './utils';
import { useProxyTypeValidation } from './useProxyTypeValidation';
import { useProxyTypes } from './proxy-types';

interface ProxyTypeOptionsProps {
  selectedProxyType: ProxyTypeEnum;
  onSelectProxyType: (value: ProxyTypeEnum) => void;
  investments: FundToProxy[];
  /** Unproxied returns info of the investment */
  rawInvestmentRanges?: (ReturnsRangeInfo | null)[];
  /** Unproxied returns info of the selected proxy, if any */
  selectedProxyRange: ReturnsRangeInfo | undefined | null;
  /** Whether to show the description of available proxy types */
  showDescription?: boolean;
}

const ProxyTypeOptions = ({
  selectedProxyType,
  onSelectProxyType,
  investments,
  rawInvestmentRanges,
  selectedProxyRange,
  showDescription = false,
}: ProxyTypeOptionsProps) => {
  const proxyTypeValidationResults = useProxyTypeValidation(investments, rawInvestmentRanges ?? [], selectedProxyRange);
  const isValid = (proxyType: ProxyTypeEnum) => !proxyTypeValidationResults[proxyType].hasOnlyErrors;
  const getFirstMessage = (proxyType: ProxyTypeEnum) =>
    proxyTypeValidationResults[proxyType].investmentInfo.find((ii) => !!ii.disabledMessage)?.disabledMessage;

  const validProxytypes = useProxyTypes().filter(isValid);

  const items = validProxytypes.map((proxyType) => ({
    label: getFormattedProxyType(proxyType),
    description: getProxyTypeDescription(proxyType),
    value: proxyType,
    tooltipContent: getFirstMessage(proxyType),
    tooltipPosition: TooltipPosition.Right,
    className: `qa-proxy-option-${proxyType.toLowerCase()}`,
  }));

  return (
    <Container>
      <ProxyContainer data-testid="qa-proxy-dropmenu">
        <ProxyTypeContainer>Proxy Type:</ProxyTypeContainer>
        <DropMenu
          height={400}
          items={items}
          selected={selectedProxyType}
          onChange={(item) => onSelectProxyType(item.value)}
          minimumItemsToTrigger={1}
        />
      </ProxyContainer>
      {showDescription && (
        <ProxyDescription>
          <strong>Available proxy types: {validProxytypes.map(getFormattedProxyType).join(', ')}</strong>
          Available proxy types are limited by the combination of selected investments, frequencies, etc...
        </ProxyDescription>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

const ProxyContainer = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  width: 306px;
  .simple-menu-item {
    font-size: 16px;
  }
  .simple-menu-item-description {
    font-size: 12px;
    line-height: 16px;
  }
`;

const ProxyTypeContainer = styled.div`
  font-weight: bold;
  font-size: 12px;
`;

const ProxyDescription = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 5px;
`;

export default ProxyTypeOptions;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { type HistoricalPortfolioErrorState, MultiHistoricalPortfolioContext } from './MultiHistoricalPortfolioContext';
import { identity, isEmpty, isNil, sortBy, uniq, uniqBy } from 'lodash';
import { MultiPortfolioReviewContext } from '../common/MultiPortfolioReviewContext';
import { type Portfolio } from 'venn-api';
import moment from 'moment';
import { getAllRanges, hasDuplicatedHistoricalInvestments, serializePath } from '../../review/helpers';
import useRangeAnalysis from '../../../../../hooks/useRangeAnalysis';
import { AnalysisSubject } from 'venn-utils';

type RootProps = {
  children: React.ReactNode;
};
export const Root = ({ children }: RootProps) => {
  const {
    data: { parsedResults, selectedIndex },
  } = useContext(MultiPortfolioReviewContext);
  const openedPortfolio = parsedResults[selectedIndex].parsedPortfolio;
  const excludedInvestments = parsedResults[selectedIndex].excludedInvestments;
  const allDates = sortBy(
    uniq(getAllTimestamps(openedPortfolio)).map((ts) => new Date(ts)),
    identity,
  );
  if (allDates.length === 0) {
    // todo: VENN-27935 handle errors when there are no dates in a historical portfolio
    return null;
  }
  if (uniqBy(allDates, (date) => moment.utc(date).format('DD MMMM YYYY')).length !== allDates.length) {
    // todo: VENN-27935 handle errors when there are duplicate dates with different timestamps in a historical portfolio
    return null;
  }
  return (
    <InnerRoot openedPortfolio={openedPortfolio} excludedInvestments={excludedInvestments} allDates={allDates}>
      {children}
    </InnerRoot>
  );
};

const getAllTimestamps = (portfolio: Portfolio): number[] => {
  if (isEmpty(portfolio.children)) {
    return portfolio.closingAllocationsTs?.map((ts) => ts[0]) ?? [];
  }
  const currentTimestamps = portfolio.closingAllocationsTs?.map((ts) => ts[0]) ?? [];
  const rest = portfolio.children.flatMap(getAllTimestamps);
  return currentTimestamps.concat(rest);
};

const getAllErrors = (portfolio: Portfolio, excludedInvestments: Set<string>): HistoricalPortfolioErrorState => {
  const populateErrors = (
    parent: Portfolio | undefined,
    portfolio: Portfolio,
    path: number[],
    mutableErrors: HistoricalPortfolioErrorState,
  ) => {
    if (isEmpty(portfolio.children)) {
      // if this is a fund
      if (portfolio.fund) {
        // handle unmapped funds
        if (isNil(portfolio.fund.id) && !excludedInvestments.has(serializePath(path).value)) {
          const closingAllocationsTs = portfolio.closingAllocationsTs ?? [];
          for (const ts of closingAllocationsTs) {
            if (mutableErrors[ts[0]]) {
              mutableErrors[ts[0]].push({ portfolioId: portfolio.id });
            } else {
              mutableErrors[ts[0]] = [{ portfolioId: portfolio.id }];
            }
          }
        }

        // check for duplicate funds
        if (parent && portfolio.fund.id) {
          const closingAllocationsTs = portfolio.closingAllocationsTs ?? [];
          for (const ts of closingAllocationsTs) {
            if (!hasDuplicatedHistoricalInvestments(parent, portfolio, path, ts[0], excludedInvestments)) {
              continue;
            }

            if (mutableErrors[ts[0]]) {
              mutableErrors[ts[0]].push({ portfolioId: portfolio.id });
            } else {
              mutableErrors[ts[0]] = [{ portfolioId: portfolio.id }];
            }
          }
        }
      }
      // todo: VENN-27935 handle other errors in the future
      return;
    }
    for (const [index, child] of portfolio.children.entries()) {
      populateErrors(portfolio, child, [...path, index], mutableErrors);
    }
  };
  const errors: HistoricalPortfolioErrorState = {};
  populateErrors(undefined, portfolio, [0], errors);
  return errors;
};

type InnerRootProps = {
  openedPortfolio: Portfolio;
  excludedInvestments: Set<string>;
  allDates: Date[];
  children: React.ReactNode;
};
const InnerRoot = ({ openedPortfolio, excludedInvestments, allDates, children }: InnerRootProps) => {
  const [selectedDate, setSelectedDate] = useState<Date>(allDates[0]);
  useEffect(() => {
    setSelectedDate(allDates[0]);
  }, [allDates]);
  const errors = useMemo(
    () => getAllErrors(openedPortfolio, excludedInvestments),
    [excludedInvestments, openedPortfolio],
  );
  const cachedSubject = useMemo(
    () => new AnalysisSubject(cleanPortfolio(openedPortfolio), 'portfolio'),
    [openedPortfolio],
  );

  const { rangeAnalysis } = useRangeAnalysis(cachedSubject);
  const ranges = useMemo(
    () => (rangeAnalysis && rangeAnalysis.rangeAnalyses.length > 0 ? getAllRanges(rangeAnalysis.rangeAnalyses[0]) : {}),
    [rangeAnalysis],
  );
  const context = useMemo(
    () => ({
      selectedDate,
      selectDate: (date: Date) => setSelectedDate(date),
      allDates,
      errors,
      rangeAnalysis: ranges,
    }),
    [allDates, selectedDate, errors, ranges],
  );
  return (
    <MultiHistoricalPortfolioContext.Provider value={context}>{children}</MultiHistoricalPortfolioContext.Provider>
  );
};

const cleanPortfolio = (portfolio: Portfolio): Portfolio => ({
  ...portfolio,
  children: portfolio.children.filter((c) => !c?.fund || !!c?.fund?.id).map(cleanPortfolio),
});

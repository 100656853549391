import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import {
  autoproxyFund,
  type ProxyTypeEnum,
  type LibrarySearchEntity,
  type ProxyMetrics,
  bulkSetProxy,
  type Fund,
} from 'venn-api';
import { GetColor, Headline3, Icon, Notifications, NotificationType } from 'venn-ui-kit';
import ProxyTypeOptions from './ProxyTypeOptions';
import { getProxyTypeNoun, useQuery } from 'venn-utils';
import { getDisabledProxyMessage } from './utils';
import { ProxyFundOptions } from './components';
import { useBulkProxyErrorMessage } from './useProxyErrorMessage';
import { useInvestmentsReturnsRanges, useProxyReturnsRange } from './useInvestmentsReturnsRanges';
import { compact, sumBy, uniq } from 'lodash';
import { ExtrapolationToggle, useExtrapolateToggle } from './components/ExtrapolationToggle';

const getDefaultProxyType = (interpolatable: boolean, hasMinDataForInterpolation: boolean): ProxyTypeEnum =>
  interpolatable ? (hasMinDataForInterpolation ? 'DESMOOTH_INTERPOLATE' : 'SUBSTITUTE') : 'BACKFILL';

interface BulkProxyPickerProps {
  /**
   * Investments we need to find a proxy for
   */
  investments: Fund[];

  /**
   * Fires with the updated investment ids when any proxy has been changed.
   */
  onProxyChange: (fundId?: string[]) => void | Promise<void>;

  onClose: () => void;
}

export const BulkProxyPicker = ({ investments, onProxyChange, onClose }: BulkProxyPickerProps) => {
  const [selectedProxyType, setSelectedProxyType] = useState<ProxyTypeEnum>(() => {
    const uniqueProxyTypes = uniq(investments.map((investment) => investment.proxyType));
    return (
      (uniqueProxyTypes.length === 1 && uniqueProxyTypes[0]) ||
      getDefaultProxyType(
        investments.every((fund) => fund.interpolatable),
        investments.every((fund) => fund.hasMinDataForInterpolation),
      )
    );
  });
  const [selectedProxyId, setSelectedProxyId] = useState<string | undefined>(() => {
    const uniqueProxyIds = uniq(investments.map((investment) => investment.proxyId));
    return uniqueProxyIds.length === 1 ? uniqueProxyIds[0] : undefined;
  });
  const [isProxiesUpdating, setIsProxiesUpdating] = useState(false);

  const numExistingProxies = investments.filter((fund) => fund.proxyId).length;

  const extrapolationToggleProps = useExtrapolateToggle(investments, selectedProxyType);
  const { shouldExtrapolate } = extrapolationToggleProps;

  const assetDescription =
    investments.length === 1
      ? `${investments.length} selected asset to be proxied`
      : `${investments.length} selected assets to be proxied`;
  const existingProxyDescription =
    numExistingProxies === 1
      ? `${numExistingProxies} asset has an existing proxy`
      : `${numExistingProxies} assets have existing proxies`;

  // TODO(VER-816, collini): refactor ProxyOptions so that we can compose the auto proxy table ourselves, and
  // remove the match% and returns chart columns when #investments > 1
  // TODO(VER-816, collini): refactor to util hook
  const bulkAutoProxyQuery = useQuery<{ results: ProxyMetrics[] }>(
    ['bulkAutoproxyFund', investments.map((investment) => investment.id)],
    async ({ signal }) => {
      const maxInvestmentsToQuery = 5;
      // TODO(VER-816, collini): AutoProxyOptions has an internal hardcoded limit of 5 for now, which we should make customizable.
      const maxResults = 5;

      const investmentsToQuery = investments.slice(0, maxInvestmentsToQuery);
      const responses = await Promise.all(
        investmentsToQuery.map((investment) => autoproxyFund(investment.id, shouldExtrapolate, signal)),
      );

      const results: ProxyMetrics[] = [];
      const totalResultsLength = sumBy(responses, (response) => response.content.results.length);
      let index = 0;
      while (results.length < Math.min(totalResultsLength, maxResults)) {
        // Take one result a time from each response, so that we get a wide sample without biasing towards any one investment
        const response = responses[index++ % responses.length];
        const result = response.content.results.shift();
        if (result) {
          results.push(result);
        }
      }

      return { results };
    },
  );

  const rawInvestmentReturnsRanges = useInvestmentsReturnsRanges(investments);

  const { data: rawProxyReturnsRange } = useProxyReturnsRange(selectedProxyId);

  const { generalMessage, investmentErrors, disableSave } = useBulkProxyErrorMessage({
    investments,
    rawInvestmentReturnsRanges,
    rawProxyReturnsRange,
    selectedProxyId,
    selectedProxyType,
    extrapolate: shouldExtrapolate,
  });

  const onSelectProxyFund = useCallback(
    async (proxyId: string) => {
      // TODO(VER-816, houssein): Modify to use the finalized bulk API when available
      setIsProxiesUpdating(true);
      const investmentsWithoutErrors = investments.filter(
        (investment) => !investmentErrors[investment.id]?.disableSave,
      );
      try {
        await bulkSetProxy(
          investmentsWithoutErrors.map((investment) => ({
            proxyId,
            fundId: investment.id,
            proxyType: selectedProxyType,
            extrapolate: shouldExtrapolate,
            numLags: 0,
          })),
        );
        onProxyChange(investmentsWithoutErrors.map(({ id }) => id));
        Notifications.notify(
          `${investmentsWithoutErrors.length} proxies updated successfully.`,
          NotificationType.SUCCESS,
        );
      } catch (e) {
        Notifications.notify('Failed to update proxies.', NotificationType.ERROR);
      } finally {
        setIsProxiesUpdating(false);
        onClose();
      }
    },
    [investments, onProxyChange, onClose, investmentErrors, selectedProxyType, shouldExtrapolate],
  );

  const getDisabledSearchResultReason = useCallback(
    (result: LibrarySearchEntity) => {
      // Note that we don't check the backend for now, because it would be too slow to do the O(Investments * SearchResult) requests.
      const allReasons = compact(
        investments.map((investment, index) =>
          getDisabledProxyMessage(result, selectedProxyType, investment, rawInvestmentReturnsRanges?.[index] ?? null),
        ),
      );

      // If it works for ANY investments, allow it.
      if (allReasons.length < investments.length) {
        return undefined;
      }

      const hasOneUniqueMessage = uniq(allReasons).length === 1;
      if (hasOneUniqueMessage) {
        return allReasons[0];
      }

      return `This investment is not eligible for ${getProxyTypeNoun(selectedProxyType)} with any of your selected investments.`;
    },
    [investments, rawInvestmentReturnsRanges, selectedProxyType],
  );

  return (
    <>
      <Headline>Add a proxy</Headline>
      <Description>
        <MainDescription>{assetDescription}</MainDescription>
        <SecondaryDescription>
          <WarningIcon type="triangle-exclamation" />
          {existingProxyDescription}
        </SecondaryDescription>
      </Description>
      <Body>
        <ProxyTypeOptions
          investments={investments}
          rawInvestmentRanges={rawInvestmentReturnsRanges}
          selectedProxyRange={rawProxyReturnsRange}
          selectedProxyType={selectedProxyType}
          onSelectProxyType={setSelectedProxyType}
          showDescription
        />
        <ExtrapolationToggle {...extrapolationToggleProps} />
        <SearchLabel>Search for an investment to use as a proxy:</SearchLabel>
      </Body>

      <ProxyFundOptions
        investmentId={investments[0].id}
        autoproxyResponse={{
          loading: bulkAutoProxyQuery.isLoading,
          result: bulkAutoProxyQuery.data ?? undefined,
        }}
        onSelectProxyFund={onSelectProxyFund}
        disableAutofocus={false}
        getDisabledSearchResultReason={getDisabledSearchResultReason}
        proxy={null}
        closePicker={onClose}
        setSelectedProxyId={setSelectedProxyId}
        disableSave={disableSave || isProxiesUpdating}
        footerDescription={
          generalMessage && (
            <Footer>
              <WarningIcon type="triangle-exclamation" /> {generalMessage}
            </Footer>
          )
        }
      />

      <InvestmentInfoTable>
        <table>
          <thead>
            <tr>
              <th>Investment Name</th>
              <th>Frequency</th>
              <th>Existing Proxy ID</th>
              <th>Error Message</th>
            </tr>
          </thead>
          <tbody>
            {investments.map((investment) => {
              const errorMessage = investmentErrors[investment.id]?.errorMessage;
              return (
                <tr key={investment.id}>
                  <td>
                    {errorMessage && <WarningIcon type="triangle-exclamation" />}
                    {investment.name}
                  </td>
                  <td>{investment.unproxiedFrequency}</td>
                  <td>{investment.proxyId}</td>
                  <td>{errorMessage || '--'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </InvestmentInfoTable>
    </>
  );
};

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
`;

const Headline = styled(Headline3)`
  line-height: normal;
  font-size: 20px;
  padding: 0px;
  height: 24px;
  margin-bottom: 10px;
`;

const Description = styled.div`
  padding: 12px 10px;
  background-color: ${GetColor.WhiteGrey};
  border-color: ${GetColor.PaleGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  flex-direction: row;
`;

const MainDescription = styled.div`
  font-weight: 600;
`;

const SecondaryDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const WarningIcon = styled(Icon)`
  color: ${GetColor.Warning};
`;

const SearchLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
`;

const InvestmentInfoTable = styled.div`
  padding: 0 20px;
  margin-top: 20px;
  table {
    width: 100%;
    border-collapse: collapse;
    th {
      text-align: left;
      padding: 8px 0;
      font-size: 12px;
      font-weight: bold;
      border-bottom: 1px solid ${GetColor.PaleGrey};
    }
    td {
      padding: 8px 0;
      font-size: 12px;
      border-bottom: 1px solid ${GetColor.PaleGrey};
    }
  }
`;

const Footer = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

import React, { useCallback, useMemo, useState } from 'react';
import type { AutoproxyResponse, ItemId, LibrarySearchEntity } from 'venn-api';
import type { SearchMenuItem } from '../../../../search-menu';
import { SearchMenuBar } from '../../../../search-menu';
import styled from 'styled-components';
import AutoproxyOptions from './AutoproxyOptions';
import { OptionsLoading } from './OptionsLoading';
import type { AnalysisSubject } from 'venn-utils';
import type { FundProxy } from '../../types';
import { SearchMenuColumn } from '../../../../search-menu/components/Columns';
import { StyledModalFooter } from './CategoryOptions';

interface ProxyFundOptionsInterface {
  /** Id of the investment being proxied */
  investmentId: string;
  /** FetchApiResult of autoproxyFund */
  autoproxyResponse: { loading: boolean; result?: AutoproxyResponse };
  /** Callback when a fund is selected as a proxy */
  onSelectProxyFund: (id: string, name: string) => void;

  /** By default, autofocus is on for the search bar. If this is true, autofocus is disabled */
  disableAutofocus: boolean;
  /** Function to determine whether the search result should be disabled or not */
  getDisabledSearchResultReason: (result: LibrarySearchEntity) => string | undefined;
  proxy: FundProxy | null;
  closePicker: () => void;
  setSelectedProxyId: (id: string) => void;
  disableSave: boolean;
  footerDescription?: React.ReactNode;
}

const SEARCH_COLUMNS = [
  SearchMenuColumn.CCY,
  SearchMenuColumn.IDENTIFIER,
  SearchMenuColumn.TYPE,
  SearchMenuColumn.AVAILABLE_DATES,
];

/**
 * Component that allows for search and selection of funds that can be used as proxies
 */
const ProxyFundOptions: React.FC<React.PropsWithChildren<ProxyFundOptionsInterface>> = ({
  autoproxyResponse,
  disableAutofocus,
  getDisabledSearchResultReason,
  investmentId,
  onSelectProxyFund,
  proxy,
  closePicker,
  setSelectedProxyId,
  disableSave,
  footerDescription,
}) => {
  // we shouldn't show / allow saving categories in this component
  const savedProxyIsNotCategory = !proxy?.proxyCategory;
  const inputAnalysisSubject =
    savedProxyIsNotCategory && proxy ? ({ id: proxy.proxyId, name: proxy.name } as AnalysisSubject) : undefined;
  const [currentQuery, setCurrentQuery] = useState<string>(savedProxyIsNotCategory ? (proxy?.name ?? '') : '');
  const [selectedSubject, setSelectedSubject] = useState<AnalysisSubject | undefined>(inputAnalysisSubject);
  const [currentValue, setCurrentValue] = useState<SearchMenuItem | undefined>(
    inputAnalysisSubject
      ? ({
          category: 'investment',
          label: inputAnalysisSubject.name,
          value: { id: inputAnalysisSubject.id, name: inputAnalysisSubject?.name } as AnalysisSubject,
        } as SearchMenuItem)
      : undefined,
  );

  const { loading: autoproxyLoading, result: autoproxyResult } = autoproxyResponse;
  const setSelectedAnalysisSubject = useCallback(
    (id: string, name: string) => {
      setSelectedProxyId(id);
      setCurrentValue({ category: 'investment', label: name, value: { id, name } as AnalysisSubject });
      setSelectedSubject({ id, name } as AnalysisSubject);
      setCurrentQuery(name);
    },
    [setSelectedProxyId],
  );

  const showAutoProxyResults = !currentQuery.length || currentQuery === proxy?.name;
  const Options = useMemo(() => {
    if (autoproxyLoading) {
      return <OptionsLoading />;
    }

    if (showAutoProxyResults) {
      return <AutoproxyOptions autoproxyResponse={autoproxyResult} onSelectProxyFund={setSelectedAnalysisSubject} />;
    }

    return null;
  }, [autoproxyLoading, autoproxyResult, setSelectedAnalysisSubject, showAutoProxyResults]);

  const excludeFromSearch: ItemId[] = useMemo(() => [{ id: investmentId, type: 'FUND' }], [investmentId]);

  const getDisabledReason = useCallback(
    (option: SearchMenuItem) => {
      const reason = option.searchResult && getDisabledSearchResultReason(option.searchResult);
      return reason || '';
    },
    [getDisabledSearchResultReason],
  );

  const isOptionDisabled = useCallback(
    (option: SearchMenuItem) => {
      if (option.category === 'tag') {
        return false;
      }
      return !!option.searchResult && !!getDisabledSearchResultReason(option.searchResult);
    },
    [getDisabledSearchResultReason],
  );

  const onSelected = useCallback(
    (option: SearchMenuItem) => {
      if (!option.value) {
        return;
      }

      setSelectedProxyId(option.value.id.toString());
      setCurrentValue({
        category: 'investment',
        label: option.value.name,
        value: { id: option.value.id, name: option.value.name } as AnalysisSubject,
      });
      setSelectedSubject(option.value);
    },
    [setSelectedProxyId],
  );

  return (
    <>
      <ContentContainer>
        <SearchMenuBar
          investmentsOnly
          autofocus={!disableAutofocus}
          proxyable
          onSelected={onSelected}
          onQueryChange={setCurrentQuery}
          excludedItems={excludeFromSearch}
          displayResultsAsBlock
          isOptionDisabled={isOptionDisabled}
          optionDisabledTooltipContent={getDisabledReason}
          columns={SEARCH_COLUMNS}
          showRecentlyAnalyzed={false}
          location="proxyPicker"
          value={currentValue}
          forceHideResults={showAutoProxyResults}
          isClearable
          clearQueryOnBlur
          closeOnSelect
          privateAssetSearchMode="PUBLIC_ONLY"
        />
        {Options}
      </ContentContainer>
      <StyledModalFooter
        submitOnEnter
        primaryLabel="Save"
        primaryDisabled={disableSave || !selectedSubject}
        primaryClassName="proxy-primary-save-button"
        onPrimaryClick={() => selectedSubject && onSelectProxyFund(String(selectedSubject.id), selectedSubject.name)}
        onSecondaryClick={closePicker}
        secondaryLabel="Cancel"
        leftChildren={footerDescription}
      />
    </>
  );
};

export default ProxyFundOptions;

const ContentContainer = styled.div`
  padding: 8px 28px 16px 28px;
`;

import {
  analyticsService,
  FREQUENCY_TO_ENUM,
  getFormattedFrequency,
  logExceptionIntoSentry,
  useFetchApiConditionally,
  useHasFF,
  useIsMounted,
} from 'venn-utils';
import type { FrequencyEnum, LibrarySearchEntity, ProxyCategoryMapping, ProxyTypeEnum } from 'venn-api';
import { autoproxyFund, getFundReturnsRange, removeFundProxy, setProxyV2 } from 'venn-api';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { FundProxy, FundToProxy } from '../types';
import {
  EllipsisTooltipSpan,
  GetColor,
  Headline3,
  Icon,
  Label,
  LetterBadge,
  Notifications,
  NotificationType,
  Tooltip,
} from 'venn-ui-kit';
import styled, { ThemeContext } from 'styled-components';
import isEqual from 'lodash/isEqual';
import type { LegendSeries } from '../../../legend/Legend';
import { Legend } from '../../../legend/Legend';
import {
  getDisabledProxyMessage,
  getReturnsRangeFromError,
  typeSupportsCategoryPicker,
  typeSupportsDesmooth,
  typeSupportsInterpolate,
} from './utils';
import ProxyTypeOptions from './ProxyTypeOptions';
import LagInput from './LagInput';
import { ProxyFundOptions } from './components';
import CategoryOptions from './components/CategoryOptions';
import { OptionsLoading } from './components/OptionsLoading';
import SliderToggle from '../../../slider-toggle/SliderToggle';
import { startCase } from 'lodash';
import useProxyErrorMessage from './useProxyErrorMessage';

export const PROXY_PICKER_WIDTH = 706;

const canShowExtrapolationToggle = (proxyType: ProxyTypeEnum, frequency: FrequencyEnum) => {
  return (
    proxyType !== 'SUBSTITUTE' &&
    proxyType !== 'EXTRAPOLATE' &&
    (frequency !== 'QUARTERLY' || typeSupportsInterpolate(proxyType))
  );
};

export interface ProxyPickerWrapperProps {
  picker: React.ReactElement;
  isProxyUpdating: boolean;
  saveUnsavedChanges: () => Promise<void>;
}

export interface ProxyPickerProps {
  wrapperRenderer?: (props: ProxyPickerWrapperProps) => React.ReactElement;
  /**
   * Investment we need to find a proxy for
   */
  investment: FundToProxy;
  /**
   * Currently chosen proxy
   */
  proxy: FundProxy | null;
  /**
   * Fires with the updated proxy when the investment's proxy has been changed.
   * The proxy will be undefined if the proxy was removed.
   */
  onProxyChange: (proxy?: FundProxy) => void | Promise<void>;
  /**
   * Optionally disable autofocus for this element
   */
  disableAutofocus?: boolean;
  /**
   * Boolean to control whether autoproxy and proxy range info are loaded on mount / on component update.
   * Useful if the proxy picker is conditionally displayed by the wrapperRenderer.
   * Defaults to true.
   */
  loadPickerData?: boolean;

  closePicker: () => void;

  readOnly?: boolean;
  reset?: boolean;
}

const getDefaultProxyType = (interpolatable: boolean, hasMinDataForInterpolation: boolean): ProxyTypeEnum =>
  interpolatable ? (hasMinDataForInterpolation ? 'DESMOOTH_INTERPOLATE' : 'SUBSTITUTE') : 'BACKFILL';

const initialLegacyPickerState = (proxy: FundProxy | null) => {
  if (proxy?.proxyCategory) {
    return undefined;
  }
  return proxy?.proxyId;
};
const initialCategoryPickerState = (proxy: FundProxy | null) => {
  return proxy?.proxyId;
};

const ProxyPicker: React.FC<React.PropsWithChildren<ProxyPickerProps>> = ({
  closePicker,
  disableAutofocus,
  investment,
  loadPickerData = true,
  readOnly,
  onProxyChange,
  proxy,
  wrapperRenderer,
  reset,
}) => {
  const isMountedRef = useIsMounted();

  const { id: investmentId, interpolatable, hasMinDataForInterpolation } = investment;
  const [selectedProxyType, setSelectedProxyType] = useState<ProxyTypeEnum>(
    proxy?.proxyType ?? getDefaultProxyType(interpolatable, hasMinDataForInterpolation),
  );
  const [isProxyUpdating, setIsProxyUpdating] = useState(false);

  const [numLags, setNumLags] = useState(investment.proxyNumLags ?? 0);
  const [suggestedNumLags, setSuggestedNumLags] = useState<number>();
  const [extrapolateToggle, setExtrapolateToggle] = useState(!!investment.extrapolate);

  const investments = useMemo(() => [investment], [investment]);

  // There are two different types of pickers which we can switch between
  // We need to remember the state of each separately, or else we might be out-of-sync
  const supportsCategoryPicker = typeSupportsCategoryPicker(selectedProxyType);
  const [proxyIdSelectedInCategoryPicker, setProxyIdSelectedInCategoryPicker] = useState(
    initialCategoryPickerState(proxy),
  );
  const [proxyIdSelectedInLegacyPicker, setProxyIdSelectedInLegacyPicker] = useState(initialLegacyPickerState(proxy));
  const selectedProxyId = supportsCategoryPicker ? proxyIdSelectedInCategoryPicker : proxyIdSelectedInLegacyPicker;
  useEffect(() => {
    if (reset) {
      setSelectedProxyType(proxy?.proxyType ?? getDefaultProxyType(interpolatable, hasMinDataForInterpolation));
      setIsProxyUpdating(false);
      setNumLags(investment.proxyNumLags ?? 0);
      setSuggestedNumLags(undefined);
      setExtrapolateToggle(!!investment.extrapolate);
      setProxyIdSelectedInCategoryPicker(initialCategoryPickerState(proxy));
      setProxyIdSelectedInLegacyPicker(initialLegacyPickerState(proxy));
    }
  }, [
    hasMinDataForInterpolation,
    interpolatable,
    investment.extrapolate,
    investment.proxyNumLags,
    proxy,
    proxy?.proxyType,
    reset,
  ]);
  const extrapolate =
    selectedProxyType === 'EXTRAPOLATE' ||
    (extrapolateToggle && canShowExtrapolationToggle(selectedProxyType, investment.unproxiedFrequency));

  // When a user selects a new option, we update the current lag as well as the suggested lag
  const onSelectedOptionLagUpdate = (lag: number) => {
    setNumLags(lag);
    setSuggestedNumLags(lag);
  };

  const autoproxyResponse = useFetchApiConditionally(loadPickerData, autoproxyFund, investmentId, extrapolate);
  const { error: investmentError, result: fetchInvestmentResult } = useFetchApiConditionally(
    loadPickerData,
    getFundReturnsRange,
    true,
    investmentId,
  );
  const { error: proxyError, result: fetchProxyResult } = useFetchApiConditionally(
    loadPickerData && Boolean(selectedProxyId),
    getFundReturnsRange,
    true,
    selectedProxyId!,
  );

  const rawInvestmentReturnsRange = fetchInvestmentResult ?? getReturnsRangeFromError(investmentError);
  const rawProxyReturnsRange = fetchProxyResult ?? getReturnsRangeFromError(proxyError);

  const getDisabledSearchResultReason = useCallback(
    (result: LibrarySearchEntity) => {
      return getDisabledProxyMessage(result, selectedProxyType, investment, rawInvestmentReturnsRange);
    },
    [investment, rawInvestmentReturnsRange, selectedProxyType],
  );

  const saveProxy = useCallback(
    async (updatedProxy: FundProxy) => {
      if (isEqual(proxy, updatedProxy)) {
        closePicker?.();
        return;
      }
      try {
        const req = {
          fundId: investmentId,
          proxyId: updatedProxy.id,
          proxyType: updatedProxy.proxyType,
          proxyCategory: updatedProxy.proxyCategory,
          numLags: updatedProxy.numLags,
          extrapolate: updatedProxy.extrapolate,
        };
        setIsProxyUpdating(true);
        await setProxyV2(req);
        await onProxyChange(updatedProxy);
        analyticsService.proxyModified({
          fundId: investmentId,
          proxyId: updatedProxy.id ?? undefined,
          proxyCategory: updatedProxy.proxyCategory ?? undefined,
          proxyType: updatedProxy.proxyType ?? undefined,
          numLags: updatedProxy.numLags ?? undefined,
          extrapolate: updatedProxy.extrapolate ?? false,
        });
        Notifications.notify('Proxy updated successfully', NotificationType.SUCCESS);
      } catch (e) {
        logExceptionIntoSentry(e);
        const message = e?.content?.message ?? 'Failed to update proxy';
        Notifications.notify(message, NotificationType.ERROR);
      }
      if (isMountedRef.current) {
        setIsProxyUpdating(false);
      }
    },
    [closePicker, investmentId, isMountedRef, onProxyChange, proxy],
  );

  const onSelectProxyFund = useCallback(
    async (id: string, name: string) => {
      await saveProxy({
        id,
        name,
        proxyCategory: undefined,
        proxyType: selectedProxyType,
        extrapolate,
      });
    },
    [saveProxy, selectedProxyType, extrapolate],
  );

  const onSelectCategory = useCallback(
    async (categoryMapping: ProxyCategoryMapping, numLags?: number) => {
      const { category, displayName, indexId } = categoryMapping;
      await saveProxy({
        id: indexId,
        name: displayName,
        proxyCategory: category,
        proxyType: selectedProxyType,
        numLags,
        extrapolate,
      });
    },
    [saveProxy, selectedProxyType, extrapolate],
  );

  const removeProxy = useCallback(async () => {
    try {
      setIsProxyUpdating(true);
      await removeFundProxy(investment.id);
      await onProxyChange();
      Notifications.notify('Proxy removed successfully', NotificationType.SUCCESS);
    } catch (e) {
      logExceptionIntoSentry(e);
      const message = e?.content?.message ?? 'Failed to remove proxy';
      Notifications.notify(message, NotificationType.ERROR);
    }
    if (isMountedRef.current) {
      setIsProxyUpdating(false);
    }
  }, [investment.id, isMountedRef, onProxyChange]);

  const { Schemes } = useContext(ThemeContext);
  const legend: LegendSeries[] = [
    {
      color: Schemes.Proxy.proxyLine,
      name: 'Proxy',
    },
    {
      color: Schemes.Proxy.subjectLine,
      name: 'Investment',
    },
  ];

  const { errorMessage, disableSave } = useProxyErrorMessage({
    investment,
    rawInvestmentReturnsRange,
    rawProxyReturnsRange,
    selectedProxyId,
    selectedProxyType,
    numLags,
    extrapolate,
  });
  const disableSaveButton = disableSave || isProxyUpdating;
  const proxyLetterBadge = proxy?.proxyType === 'DESMOOTH_INTERPOLATE' ? 'DI' : (proxy?.proxyType?.[0] ?? 'U');
  const hasExtrapolationFF = useHasFF('extrapolation_ff');
  const picker = (
    <Container className="qa-proxy-picker">
      <React.Suspense
        fallback={
          <LoadingWrapper>
            <OptionsLoading />
          </LoadingWrapper>
        }
      >
        <LabelAndHeaderContainer>
          {proxy && <StyledNormalLabel>Current Proxy:</StyledNormalLabel>}
          <Header>
            {proxy && (
              <LetterBadge
                size={24}
                fontSize={12}
                letter={proxyLetterBadge}
                color={Schemes.Proxy.badgeBg}
                textColor={Schemes.Proxy.badgeText}
              />
            )}
            <Headline className="qa-proxy-name">
              {proxy ? (
                <EllipsisTooltipSpan maxWidth={460}>&nbsp;{proxy.name}&nbsp;</EllipsisTooltipSpan>
              ) : (
                'Add a proxy'
              )}
            </Headline>
            {proxy && !readOnly && (
              <TrashIcon type="trash" onClick={isProxyUpdating ? undefined : removeProxy} disabled={isProxyUpdating} />
            )}
          </Header>
        </LabelAndHeaderContainer>
        <AssetDescriptionContainer>
          <AssetDetailPanel>
            <AssetDetailPanelLeft>
              <AssetDetailPanelNameHeader>Asset Name</AssetDetailPanelNameHeader>
              <div data-testid="qa-proxy-base-asset-name">{investment.name}</div>
            </AssetDetailPanelLeft>
            <AssetDetailPanelRight>
              <AssetDetailPanelNameHeader>Report Frequency</AssetDetailPanelNameHeader>
              <div data-testid="qa-proxy-report-frequency">
                {startCase(getFormattedFrequency(investment.unproxiedFrequency)) || investment.proxyId}
                {investment.proxyId && FREQUENCY_TO_ENUM[investment.frequency] !== investment.unproxiedFrequency ? (
                  <span>
                    {' '}
                    <ArrowRightIcon type="arrow-right-long" />{' '}
                    {startCase(getFormattedFrequency(FREQUENCY_TO_ENUM[investment.frequency]))}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </AssetDetailPanelRight>
          </AssetDetailPanel>
        </AssetDescriptionContainer>
        {!readOnly && (
          <div>
            <InputContainer>
              <ProxyTypeOptions
                selectedProxyType={selectedProxyType}
                onSelectProxyType={setSelectedProxyType}
                investments={investments}
                rawInvestmentRanges={[rawInvestmentReturnsRange]}
                selectedProxyRange={rawProxyReturnsRange}
              />
              {typeSupportsDesmooth(selectedProxyType) && (
                <LagInput
                  frequency={investment.unproxiedFrequency}
                  onLagUpdate={setNumLags}
                  suggestedNumLags={suggestedNumLags}
                  numLags={numLags}
                />
              )}
              {hasExtrapolationFF && canShowExtrapolationToggle(selectedProxyType, investment.unproxiedFrequency) && (
                <ToggleContainer data-testid="qa-proxy-extrapolation-toggle">
                  Extrapolation:
                  <Tooltip
                    usePortal
                    data-testid="qa-extrapolation-tooltip"
                    content="Enable extrapolation to extend performance forward."
                  >
                    <QuestionMark type="question-circle" />
                  </Tooltip>
                  <SliderToggle toggled={extrapolateToggle} onToggle={() => setExtrapolateToggle(!extrapolateToggle)} />
                </ToggleContainer>
              )}
            </InputContainer>
            {errorMessage && (
              <InputContainer>
                <span>
                  <WarningIcon type="triangle-exclamation" /> {errorMessage}
                </span>
              </InputContainer>
            )}
          </div>
        )}
        {!readOnly && (
          <>
            {(typeSupportsDesmooth(selectedProxyType) || typeSupportsInterpolate(selectedProxyType)) &&
              selectedProxyType !== 'DESMOOTH_INTERPOLATE' &&
              investment.unproxiedFrequency === 'QUARTERLY' && (
                <NotificationContainer className="qa-promote-desmooth">
                  <IconStack className="fa-stack">
                    <CommentIcon type="comment-alt" className="fa-stack-2x" />
                    <LightbulbIcon type="lightbulb" className="fa-stack-1x" />
                  </IconStack>
                  <NotificationText>Try combining desmoothing with interpolation for optimal results.</NotificationText>
                </NotificationContainer>
              )}
            <CustomLegend series={legend} />
            {supportsCategoryPicker && (
              <CategoryOptions
                selectedProxyId={selectedProxyId}
                setSelectedProxyId={setProxyIdSelectedInCategoryPicker}
                onSelect={onSelectCategory}
                proxyType={selectedProxyType}
                fundId={investment.id}
                numLags={numLags}
                proxy={proxy}
                closePicker={closePicker}
                onSelectedOptionLagUpdate={onSelectedOptionLagUpdate}
                disableSave={disableSaveButton}
              />
            )}
            {!supportsCategoryPicker && (
              <ProxyFundOptions
                investmentId={investmentId}
                autoproxyResponse={autoproxyResponse}
                onSelectProxyFund={onSelectProxyFund}
                disableAutofocus={!!disableAutofocus}
                getDisabledSearchResultReason={getDisabledSearchResultReason}
                proxy={proxy}
                closePicker={closePicker}
                setSelectedProxyId={setProxyIdSelectedInLegacyPicker}
                disableSave={disableSaveButton}
              />
            )}
          </>
        )}
      </React.Suspense>
    </Container>
  );

  return wrapperRenderer
    ? wrapperRenderer({
        picker,
        isProxyUpdating,
        saveUnsavedChanges: () => Promise.resolve(),
      })
    : picker;
};

const LoadingWrapper = styled.div`
  min-height: 450px;
  padding-top: 200px;
`;

const Headline = styled(Headline3)`
  line-height: normal;
  font-size: 20px;
  padding: 0px;
  height: 24px;
`;

const LabelAndHeaderContainer = styled.div`
  margin: 8px 28px 0px 28px;
  padding-top: 18px;
`;
const AssetDescriptionContainer = styled.div`
  padding: 10px 0px 10px 0px;
  margin: 0 28px;
`;
const StyledNormalLabel = styled(Label)`
  font-weight: normal;
  font-size: 14px;
  margin-top: 8px;
`;

const Container = styled.div`
  width: ${PROXY_PICKER_WIDTH}px;
  background-color: ${GetColor.White};
  > label {
    display: inline-block;
    padding: 0 15px;
    padding-top: 15px;
  }
`;
Container.displayName = 'ProxyPickerContainer';

const Header = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
`;

const AssetDetailPanel = styled.div`
  padding: 10px 0px;
  background-color: ${GetColor.WhiteGrey};
  border-color: ${GetColor.PaleGrey};
  display: flex;
  font-size: 14px;
  flex-direction: row;
  justify-content: space-between;
`;
const AssetDetailPanelNameHeader = styled.div`
  color: ${GetColor.HintGrey};
  font-size: 12px;
`;
const AssetDetailPanelLeft = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 22px;
  flex-grow: 3;
  padding-left: 28px;
  padding-right: 28px;
  max-width: 400px;
`;
const AssetDetailPanelRight = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 22px;
  flex-grow: 1;
  padding-left: 16px;
  padding-right: 40px;
  width: 154px;
  border-left: 1px solid ${GetColor.Grey};
`;

const ArrowRightIcon = styled(Icon)`
  color: ${GetColor.MidGrey2};
`;

const TrashIcon = styled(Icon)<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? GetColor.Grey : GetColor.Primary.Dark)};
  font-size: 20px;
  margin-bottom: 4px;
  cursor: pointer;
  margin: inherit;
  vertical-align: middle;
  &:hover {
    color: ${GetColor.Primary.Main};
  }
`;

const CustomLegend = styled(Legend)`
  font-size: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 5px 28px 5px 0px;
  padding: 0px;
  color: ${GetColor.MidGrey2};
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  padding: 6px 28px 6px 28px;
`;

const NotificationContainer = styled.div`
  padding: 0px 0px 6px 28px;
  font-size: 14px;
`;

const IconStack = styled.span`
  vertical-align: middle;
`;

const CommentIcon = styled(Icon)`
  color: ${GetColor.Primary.Light};
`;

const WarningIcon = styled(Icon)`
  color: ${GetColor.Warning};
`;

const LightbulbIcon = styled(Icon)`
  color: ${GetColor.Primary.Dark};
  line-height: 20px;
`;

const NotificationText = styled.span`
  color: ${GetColor.Primary.Dark};
`;

const QuestionMark = styled(Icon)`
  margin-left: 4px;
  color: ${GetColor.Primary.Dark};
  font-size: 10px;
`;

const ToggleContainer = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
`;

export default ProxyPicker;

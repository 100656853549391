import React from 'react';
import { useRecoilValue } from 'recoil';
import { isEmpty } from 'lodash';

import { hasDisabledIndividualBenchmark } from 'venn-utils';
import {
  blockBenchmarkConfig,
  blockCustomizableMetricsMap,
  blockLimitedRequestSubjects,
  blockSettings,
} from 'venn-state';

import { Block } from '../../core';
import { CommonBenchmark } from '../common-benchmark';
import { BenchmarkSubject } from './benchmark-subject';
import { InvestmentSubject } from './investment-subject';

import { useAppPrintMode } from '../../../../print';
import { getUniqueKeyFromStudioRequestSubject } from '../../../../studio-blocks';

interface DefaultSubjectsProps {
  id: string;
  metric: string;
  relativeToBenchmark: boolean;
}

export const DefaultSubjects = ({ id, metric, relativeToBenchmark }: DefaultSubjectsProps) => {
  const { inPrintModeOrReportLab } = useAppPrintMode();

  const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(id));
  const blockSetting = useRecoilValue(blockSettings(id));

  const subjects = useRecoilValue(blockLimitedRequestSubjects(id));
  const hasIndividualBenchmarks =
    benchmarkConfig.type === 'INDIVIDUAL' && !hasDisabledIndividualBenchmark(blockSetting?.customBlockType);

  const metricDefinitionsMap = useRecoilValue(blockCustomizableMetricsMap(id));
  const requiresBenchmark = relativeToBenchmark || metricDefinitionsMap[metric]?.needsBenchmark;

  const showSubjects = (!inPrintModeOrReportLab || (inPrintModeOrReportLab && requiresBenchmark)) && !isEmpty(subjects);

  if (!showSubjects) {
    return null;
  }

  return (
    <Block.Subjects id={id}>
      {benchmarkConfig?.subject && <CommonBenchmark id={id} benchmarkSubject={benchmarkConfig?.subject} />}
      {benchmarkConfig?.subject && subjects.length > 0 && <Block.SubjectsSeparator />}
      {subjects.map((subject) => (
        <Block.SubjectsRow key={getUniqueKeyFromStudioRequestSubject(subject)}>
          <InvestmentSubject id={id} subject={subject} />
          {hasIndividualBenchmarks && (
            <>
              <Block.SubjectsSeparator />
              <BenchmarkSubject subject={subject} />
            </>
          )}
        </Block.SubjectsRow>
      ))}
    </Block.Subjects>
  );
};

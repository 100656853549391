import React, { useCallback, useState } from 'react';
import {
  Button,
  ExternalActivityListener,
  GetColor,
  Icon,
  ItemIcon,
  ItemType,
  ItemTypePill,
  LoadingSize,
  portalMenuIgnoreActivityClassName,
  Radio,
  RelativePortal,
  Spinner,
  Tooltip,
  ZIndex,
} from 'venn-ui-kit';
import styled from 'styled-components';
import { ModalFooter, SearchBar } from 'venn-components';
import { getPeerGroups, type PeerGroup, type PeerGroupIdentifier } from 'venn-api';
import { useQuery } from '@tanstack/react-query';
import {
  analyticsService,
  assertExhaustive,
  isRequestSuccessful,
  logExceptionIntoSentry,
  ReturnsDataLibraryRoute,
  useHasFF,
} from 'venn-utils';
import { isEmpty, isNil } from 'lodash';

interface PeerGroupEditorProps {
  onClose: () => void;
  onApply: (id: PeerGroupIdentifier) => void;
  selectedPeerGroupId?: PeerGroupIdentifier;
}

const PEER_GROUP_SEARCH_CACHE_KEY = 'peer-group-search';
const LEFT_MARGIN = 16;

interface PeerGroupRowProps {
  selectedPeerGroupId?: PeerGroupIdentifier;
  peerGroup: PeerGroup;
  onClick: (id: PeerGroupIdentifier) => void;
}

export const PeerGroupRow = ({ selectedPeerGroupId, peerGroup, onClick }: PeerGroupRowProps) => {
  const id = peerGroup.identifier.categoryId;
  return (
    <PeerGroupRowContainer key={id}>
      <PeerGroupNameContainer>
        <Radio
          inputId={id}
          checked={selectedPeerGroupId?.categoryId === id}
          onChange={(value) => value && onClick(peerGroup.identifier)}
        />
        <ItemTypePill
          item={peerGroup.identifier.type === 'morningstar-peer-group-id' ? ItemType.PeerGroup : ItemType.SavedSearch}
        />
        <ItemIcon className="peer-group-icon" item={ItemType.PeerGroup} />
        <StyledName onClick={() => onClick(peerGroup.identifier)}>{peerGroup.identifier.categoryName}</StyledName>
      </PeerGroupNameContainer>
      <PeerGroupConstituentsContainer>
        {isNil(peerGroup.constituents) ? 'Dynamic' : peerGroup.constituents}
        <OpenPeerGroupInLibraryLink id={peerGroup.identifier} />
      </PeerGroupConstituentsContainer>
    </PeerGroupRowContainer>
  );
};

const PeerGroupNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  label {
    margin-right: 0;
  }
  .qa-PEER_GROUP-type-pill {
    height: 19px;
    line-height: 17px;
  }
`;
const PeerGroupConstituentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  text-align: center;
  line-height: 16px;
  font-weight: bold;
  gap: 4px;
  color: ${GetColor.Primary.Dark};
`;

interface OpenPeerGroupInLibraryLinkProps {
  id: PeerGroupIdentifier;
}

const getLibraryLinkFromPeerGroupIdentifier = (id: PeerGroupIdentifier) => {
  switch (id.type) {
    case 'morningstar-peer-group-id':
      return `${ReturnsDataLibraryRoute}?morningstarCategories=${encodeURIComponent(id.categoryName)}&name=&order=desc&page=1`;
    default:
      throw assertExhaustive(id.type, 'unexpected peer group type');
  }
};
const OpenPeerGroupInLibraryLink = ({ id }: OpenPeerGroupInLibraryLinkProps) => {
  const link = getLibraryLinkFromPeerGroupIdentifier(id);
  return (
    <StyledButton
      data-testid={`open-${id.categoryName}-in-library`}
      flat
      iconPrefix="far"
      icon="arrow-up-right-from-square"
      onClick={() => window.open(link, '_blank')}
    />
  );
};

const StyledButton = styled(Button)`
  padding: 0 0 2px 0;
  min-height: 18px;
  min-width: 18px;
`;
export const SelectPeerGroup = ({ selectedPeerGroupId, onClose, onApply }: PeerGroupEditorProps) => {
  const hasCustomPeerGroups = useHasFF('custom_peer_groups_ff');
  const [selectedId, setSelectedId] = useState<PeerGroupIdentifier | undefined>(selectedPeerGroupId);
  const [searchText, setSearchText] = useState<string>('');

  const onApplySelectedPeerGroup = useCallback(() => {
    if (!selectedId) {
      return;
    }
    onApply(selectedId);
    analyticsService.peerGroupSelected({
      peerGroupId: selectedId.categoryId,
      peerGroupName: selectedId.categoryName,
    });
    onClose();
  }, [selectedId, onApply, onClose]);

  const { data: peerGroups, isLoading } = useQuery<PeerGroup[]>([PEER_GROUP_SEARCH_CACHE_KEY, searchText], async () => {
    const result = await getPeerGroups({
      name: searchText,
      source: hasCustomPeerGroups ? ('ALL' as const) : ('MORNINGSTAR' as const),
      limit: hasCustomPeerGroups ? 5 : 10,
    });

    const totalResults = isRequestSuccessful(result) ? (result?.content?.length ?? 0) : 0;
    analyticsService.searchQueryEntered({
      totalResults,
      visibleResults: totalResults,
      query: searchText ?? '',
      location: 'peer-group-editor',
      quickFilters: [],
      tagFilters: [],
      typeFilters: [],
      currencyFilters: [],
      dataSourceFilters: [],
      metricFilters: [],
      assetTypeFilters: [],
    });

    if (isRequestSuccessful(result)) {
      return result.content;
    }
    logExceptionIntoSentry('Something went wrong while fetching available peer groups. Please try again.');
    return [];
  });

  return (
    <RelativePortal expectedHeight={480} style={{ zIndex: ZIndex.Cover }} onOutClick={onClose}>
      <ExternalActivityListener
        ignoreActivityFromClassName={portalMenuIgnoreActivityClassName}
        onExternalActivity={onClose}
      >
        <Container>
          <Header>Select a Peer Group</Header>
          <SearchBar
            placeholder="Search for a Peer Group"
            style={{ margin: 16 }}
            onChange={(v) => setSearchText(v)}
            debounce={300}
          />
          <TableContainer>
            {isLoading ? (
              <LoadingContainer>
                <Spinner size={LoadingSize.nano} />
                Searching...
              </LoadingContainer>
            ) : isEmpty(peerGroups) ? (
              <NoResultsContainer>No results found.</NoResultsContainer>
            ) : (
              <PeerGroupsContainer>
                <PeerGroupsHeaderRow key="peer-groups-header-row">
                  Peer Groups
                  <div>
                    # of Investments &nbsp;
                    <Tooltip
                      usePortal
                      content={<div>Links below take you to the filtered Data Library</div>}
                      maxWidth={400}
                    >
                      <Icon prefix="far" type="info-circle" />
                    </Tooltip>
                    &nbsp;
                  </div>
                </PeerGroupsHeaderRow>
                {(peerGroups ?? []).map((peerGroup) => (
                  <PeerGroupRow
                    key={peerGroup.identifier.categoryId}
                    onClick={setSelectedId}
                    selectedPeerGroupId={selectedId}
                    peerGroup={peerGroup}
                  />
                ))}
              </PeerGroupsContainer>
            )}
          </TableContainer>
          <ModalFooter
            onCancel={onClose}
            onPrimaryClick={onApplySelectedPeerGroup}
            primaryDisabled={!selectedId || selectedId.categoryId === selectedPeerGroupId?.categoryId}
            primaryLabel="SELECT"
          />
        </Container>
      </ExternalActivityListener>
    </RelativePortal>
  );
};

const PeerGroupRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 19px;
`;

const LoadingContainer = styled.div`
  font-weight: bold;
  gap: 4px;
`;

const NoResultsContainer = styled.div`
  font-weight: bold;
`;

const PeerGroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const PeerGroupsHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  padding: 0 ${LEFT_MARGIN}px;
  border-bottom: 2px solid ${GetColor.Grey};
  background-color: ${GetColor.PaleGrey};
  height: 49px;
`;
const Container = styled.div`
  z-index: ${ZIndex.Front};
  padding: 0px;
  border: 4px solid ${GetColor.Primary.Main};
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
  background-color: ${GetColor.White};
  width: 740px;
  display: flex;
  flex-direction: column;
`;

const StyledName = styled.div`
  cursor: pointer;
`;

const TableContainer = styled.div`
  margin: 0 ${LEFT_MARGIN}px;
`;

import React, { useMemo } from 'react';
import type { Fund } from 'venn-api';
import { Button } from 'venn-ui-kit';
import { BulkProxyPicker } from '../modals/pickers/proxy-picker/BulkProxyPicker';
import { useInvestmentsReturnsRanges } from '../modals/pickers/proxy-picker/useInvestmentsReturnsRanges';
import { useProxyTypeValidation } from '../modals/pickers/proxy-picker/useProxyTypeValidation';
import SidePanelOverlay from '../side-panel-overlay/SidePanelOverlay';
import { useModal } from 'venn-utils';

interface BulkProxySectionProps {
  selectedFundIds: string[];
  funds: Fund[];
  onProxyChange: (fundIds?: string[]) => void | Promise<void>;
}

export const BulkProxySection = ({ selectedFundIds, funds, onProxyChange }: BulkProxySectionProps) => {
  const [isOpen, onOpen, onClose] = useModal();

  const selectedFunds = useMemo(
    () => funds.filter((fund) => selectedFundIds.includes(fund.id)),
    [funds, selectedFundIds],
  );

  const rawInvestmentReturnsRanges = useInvestmentsReturnsRanges(selectedFunds);
  const proxyTypeValidationResults = useProxyTypeValidation(selectedFunds, rawInvestmentReturnsRanges, undefined);
  const selectableProxyTypes = Object.keys(proxyTypeValidationResults).filter(
    (x) => !proxyTypeValidationResults[x].hasSomeErrors,
  );

  return (
    <>
      <Button onClick={onOpen}>{selectedFunds.length > 1 ? 'bulk add proxy' : 'add proxy'}</Button>
      <div>
        Allowed Proxy Types: {selectableProxyTypes.join(', ')}
        <br />
        Selected funds: {selectedFunds.map((x) => x.name).join(', ')}
      </div>
      <SidePanelOverlay
        margin={0}
        side="right"
        isOpen={isOpen}
        handleClose={onClose}
        content={<BulkProxyPicker investments={selectedFunds} onProxyChange={onProxyChange} onClose={onClose} />}
      />
    </>
  );
};
